#root,
html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: #aaa;
  color: #fff;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
